// @flow
import * as React from 'react'

export default function InnerLogo() {
  return (
    <>
      <div className="root">
        <svg width="135" height="38">
          <use xlinkHref="#logo" />
        </svg>
        <span className="version">Prime</span>
      </div>

      <style jsx>{`
        .root {
          position: relative;
          display: inline-flex;
          padding-top: 3px;
        }

        .version {
          position: absolute;
          right: 0;
          top: 0;

          transform: translateX(100%);

          font-size: 10px;
          font-weight: 700;
          line-height: 1;

          opacity: 0.2;
        }
      `}</style>
    </>
  )
}
