// @flow
import * as React from 'react'

import { TextInputFieldV2 as TextInputField } from 'components/form'
import InputSuggestions from 'components/InputSuggestions'

export type OptionType = { label: string, annotation?: string, value: any }

type Props = {
  options: Array<OptionType>,
  field: { name: string, value: any },
  form: { setFieldValue: Function },
}

export default function SelectInput({ options, field, form, ...restProps }: Props) {
  const getOptionLabel = React.useCallback(
    function getOptionLabel(value) {
      return options.find((option) => option.value === value)?.label
    },
    [options]
  )

  const [filter, setFilter] = React.useState(() => getOptionLabel(field.value) || '')
  const [isFilterInputFocused, setFilterInputFocused] = React.useState(false)
  const [showSuggestions, setShowSuggestions] = React.useState(false)
  const filteredOptions =
    typeof filter === 'string'
      ? options.filter((option) =>
          option.label
            .trim()
            .toLowerCase()
            .includes(filter.trim().toLowerCase())
        )
      : options

  React.useEffect(() => {
    if (!isFilterInputFocused) {
      setFilter(getOptionLabel(field.value) || '')
    }
  }, [field.value, isFilterInputFocused, getOptionLabel])

  function handleFocus() {
    setFilterInputFocused(true)

    if (!field.value) {
      setShowSuggestions(true)
    }
  }

  function handleBlur() {
    setFilterInputFocused(false)
    setShowSuggestions(false)

    if (filteredOptions.length === 1 && filteredOptions[0].label === filter) {
      form.setFieldValue(field.name, filteredOptions[0].value)
    }
  }

  function handleSuggestionSelect(option) {
    form.setFieldValue(field.name, option.value)
  }

  function handleFilterChange(event) {
    const nextValue = event?.target?.value

    setFilter(nextValue)
    setShowSuggestions(true)
  }

  return (
    <div className="root">
      <TextInputField
        {...restProps}
        autoComplete="nope"
        value={filter}
        forceFloat={Boolean(filter || field?.value) || undefined}
        onChange={handleFilterChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      {showSuggestions ? (
        <InputSuggestions 
          suggestions={filteredOptions}
          onSelect={handleSuggestionSelect}
        />
      ) : null}

      <style jsx>{`
        .root {
          position: relative;
        }
      `}</style>
    </div>
  )
}
