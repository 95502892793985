// @flow
import * as React from 'react'
import cn from 'clsx'

import { fonts, colors } from 'utils/style'
import { type OptionType } from 'components/form/SelectInputField'

type Props = {
  suggestions: Array<OptionType>,
  selectedIndex?: ?number,
  onSelect: Function,
}

export default function InputSuggestions({
  suggestions,
  selectedIndex,
  onSelect,
}: Props) {
  return (
    <nav>
      {suggestions.map((suggestion, index) => (
        <button
          key={suggestion.value}
          type="button"
          onMouseDown={() => onSelect(suggestion)}
          className={cn({ selected: selectedIndex === index })}
        >
          <div className="content">
            {suggestion.label}

            {suggestion.annotation ? (
              <div className="annotation">{suggestion.annotation}</div>
            ) : null}
          </div>
        </button>
      ))}

      <style jsx>{`
        nav {
          position: absolute;
          left: 0;
          right: 0;
          bottom: -10px;
          z-index: 10;
          transform: translateY(100%);
          display: flex;
          flex-direction: column;
          max-height: 30vh;
          border-radius: 6px;
          
          overflow-y: auto;
          background: ${colors.white};
          box-shadow: 0px 14px 34px rgba(0, 0, 0, 0.1);
          scroll-snap-type: y mandatory;
        }
        nav button {
          scroll-snap-align: start;
          padding: 0;
          margin: 0;
          border: none;
          text-align: left;
          font-family: ${fonts.IBMPlex};
          font-size: 14px;
          line-height: 1.2;
          font-weight: normal;
          background: ${colors.white};
          color: ${colors.grayText};
          cursor: pointer;
        }
        nav button.selected,
        nav button:hover {
          background-color: ${colors.faqBg};
        }
        nav button .content {
          padding: 12px 28px;
        }
        .annotation {
          font-size: 0.9em;
          color: ${colors.lightGraySecondary};
        }
      `}</style>
    </nav>
  )
}