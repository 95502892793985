// @flow
import * as React from 'react'

import { SelectInputField } from 'components/form'

import { useCountry } from 'lib/withCountry'

import citiesKZ from 'data/cities_kz.json'
import citiesKG from 'data/cities_kg.json'

export default function CitySelectInputField(props: Object) {
  const { country } = useCountry()

  const options = React.useMemo(() => {
    const { regions: cities } = country === 'kz' ? citiesKZ : citiesKG
    const hasDuplicatesIndices = new Set()
    const result = []

    for (let i = 0; i < cities.length; i++) {
      const city = cities[i]
      const firstDuplicateIndex = cities
        .slice(0, i)
        .findIndex(({ title }) => title === city.title)

      if (firstDuplicateIndex > -1) {
        hasDuplicatesIndices.add(firstDuplicateIndex)
        hasDuplicatesIndices.add(i)
      }

      result.push({ value: city.id, label: city.title })
    }

    for (let duplicateIndex of hasDuplicatesIndices) {
      // $FlowFixMe
      result[duplicateIndex].annotation = cities[duplicateIndex].cached_parent
    }

    return result
  }, [country])

  return <SelectInputField options={options} {...props} />
}