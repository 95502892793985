// @flow
type LatLng = {
  lat: number,
  lng: number
}

type LatLngBounds = LatLng

type GeocoderLocationType = string

type Suggestion = {
  types: Array<string>,
  formatted_address: string,
  address_components: Array<{
    short_name: string,
    long_name: string,
    postcode_localities: Array<string>,
    types: Array<string>
  }>,
  partial_match: boolean,
  place_id: string,
  postcode_localities: Array<string>,
  geometry: {
    location: LatLng,
    location_type: GeocoderLocationType,
    viewport: LatLngBounds,
    bounds: LatLngBounds
  }
}

type GeocoderResult = Array<Suggestion>

export const getSuggestions = (address: string, options: Object = {}): Promise<GeocoderResult> => {
  return new Promise((resolve, reject) => {
    if (
      typeof window !== 'undefined' &&
      typeof window.google !== 'undefined' &&
      window.google.maps
    ) {
      const geocoder = new window.google.maps.Geocoder()
      const OK = window.google.maps.GeocoderStatus.OK

      geocoder.geocode({ address, ...options }, (results, status) => {
        if (status !== OK) {
          reject(status)
        }
        resolve(results)
      })
    } else {
      reject(new Error('Google Maps API is uninitialized'))
    }
  })
}