// @flow
import * as React from 'react'
import cn from 'clsx'
import { Field } from 'formik'

import { colors, fonts, transition, media } from '../../utils/style'

type Props = {
  size: number,
  field: Object,
  form: Object,
  children: React.Node
}

function CheckboxInput({ size = 24, field, form, children }: Props) {
  const onClick = () => form.setFieldValue(field.name, !field.value)

  return (
    <button type="button" className="root" onClick={onClick}>
      <div className={cn('checkmark', { selected: Boolean(field.value) })}>
        <svg width="14" height="10">
          <use xlinkHref="#checkmark" />
        </svg>
      </div>

      <div className="label">{children}</div>

      <style jsx>{`
        .root {
          display: flex;
          align-items: center;
          padding: 0;

          border: none;
          outline: none;

          font: inherit;

          background: transparent;
          cursor: pointer;
        }

        .checkmark {
          display: flex;
          align-items: center;
          justify-content: center;
          width: ${size}px;
          height: ${size}px;
          margin-right: 11px;
          border: 1px solid ${colors.faqStroke};
          border-radius: 4px;

          color: #3ac389;
        }

        .root:hover .checkmark,
        .root:focus .checkmark {
          border-color: ${colors.inputBorderFocus};
        }

        .checkmark svg {
          opacity: 0;

          transition: opacity 0.1s ${transition.timingFunction};
        }

        .root:hover .checkmark svg {
          opacity: 0.1;
        }

        .root .checkmark.selected svg {
          opacity: 1;
        }

        .label {
          flex: 1;

          font-family: ${fonts.IBMPlex};
          line-height: 1;
          font-size: 12px;

          color: ${colors.grayText};
        }

        @media (min-width: ${media.desktop}px) {
          .checkmark {
            margin-right: 18px;
          }

          .label {
            font-size: 14px;
          }
        }
      `}</style>
    </button>
  )
}

export default function CheckboxInputField(props: Object) {
  return <Field {...props} component={CheckboxInput} />
}
