// @flow
import * as React from 'react'
import cn from 'clsx'
import cyrillicToTranslit from 'cyrillic-to-translit-js'

import { type FieldProps } from 'formik'

import useCombinedRefs from 'lib/useCombinedRefs'
import { fieldHasError } from 'utils/validation'
import { colors, fonts, transition, media } from 'utils/style'

const PADDING_LEFT_MOBILE = 20
const PADDING_LEFT_DESKTOP = 28

const PREFIX_CONTENT_WIDTH = 11

const PREFIX_PADDING_BOTTOM_MOBILE = 15
const PREFIX_PADDING_BOTTOM_DESKTOP = 16

type TransliteratorType = {
  transform: (value: string) => string,
}

type Props = FieldProps & {
  label: string,

  value?: any,
  onFocus?: Function,
  onBlur?: Function,
  onChange?: Function,
  forceErrorVisibility?: boolean,
  forceFloat: boolean,
  autoTransliterate?: boolean,
  multiline?: boolean,
  disabled?: boolean,
}

function TextInputField(props: Props, ref: any) {
  const {
    field,
    form,
    label,
    autoTransliterate = false,
    prefix,
    multiline,
    disabled,
    onFocus,
    onBlur,
    onChange,
    forceErrorVisibility,
    forceFloat,
    ...restProps
  } = props
  const inputRef = React.useRef()
  const combinedRef = useCombinedRefs(ref, inputRef)
  const transliterator: ?TransliteratorType = React.useMemo(
    () => (autoTransliterate ? cyrillicToTranslit() : null),
    [autoTransliterate]
  )

  const previousValueRef = React.useRef(field?.value)
  const [isFocused, setFocusState] = React.useState(false)
  const [shouldFloat, setShouldFloat] = React.useState(false)
  const name = field?.name ?? restProps.name

  const hasValue = Boolean(field?.value)
  const reallyShouldFloat = forceFloat || shouldFloat || hasValue || isFocused
  const hasError = fieldHasError(name, form, forceErrorVisibility)

  const Tag = multiline ? 'textarea' : 'input'

  const assignValue = React.useCallback(
    function (nextValue: any) {
      setShouldFloat(Boolean(field?.value))

      form?.setFieldValue(field?.name, nextValue)
    },
    [form, field, setShouldFloat]
  )

  React.useEffect(() => {
    if (field?.value && field.value !== previousValueRef.current) {
      assignValue(field.value)
    }

    previousValueRef.current = field?.value
  }, [field, assignValue])

  // eslint-disable-next-line no-undef
  function handleChange(event: SyntheticInputEvent<HTMLInputElement>) {
    const {
      target: { value },
    } = event

    assignValue(value)
  }

  function handleFocus(...args: Array<*>) {
    setFocusState(true)
    setShouldFloat(true)

    if (typeof onFocus === 'function') {
      onFocus(...args)
    }
  }

  function handleBlur(...args: Array<*>) {
    const currentInput = combinedRef.current
    const isFocused =
      Boolean(currentInput) && currentInput === document.activeElement

    if (props.autoTransliterate && transliterator) {
      assignValue(transliterator.transform(field?.value))
    }

    setFocusState(isFocused)
    setShouldFloat(isFocused)

    if (typeof onBlur === 'function') {
      onBlur(...args)
    }

    if (field && form) {
      form.setFieldTouched(field.name, true)
    }
  }

  function handleAutoFill(event: AnimationEvent) {
    setShouldFloat(event.animationName.includes('onAutoFillStart'))
  }

  return (
    <div className="root">
      <div
        className={cn('field', {
          'has-error': hasError,
          focused: isFocused,
          'with-prefix': prefix,
          floating: reallyShouldFloat,
          multiline,
        })}
      >
        <span className={cn('label', { floating: reallyShouldFloat })}>
          {label}
        </span>
        {prefix ? <span className="prefix">$</span> : null}
        <Tag
          ref={combinedRef}
          disabled={disabled}
          name={name}
          onChange={typeof onChange === 'function' ? onChange : handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onAnimationStart={handleAutoFill}
          value={field?.value}
          {...restProps}
        />
      </div>
      {hasError && <div className="error">{form.errors[field.name]}</div>}

      <style jsx>{`
        .field {
          position: relative;
          z-index: 1;
          display: flex;
          align-items: flex-end;
          border: 1px solid ${colors.inputBorder};
          border-radius: 4px;
          height: 60px;
          background: #fff;
        }
        .field.multiline {
          min-height: 60px;
          height: auto;
          padding: 14px 0 0;
        }
        input,
        textarea {
          flex-grow: 1;
          width: 100%;
          max-width: 100%;
          padding: 14px 14px 0 ${PADDING_LEFT_MOBILE}px;
          border: none;
          outline: none;
          font-family: ${fonts.IBMPlex};
          font-size: 14px;
          line-height: 1;
          font-weight: normal;
          color: ${colors.grayText};
          background: transparent;
          transition: background-color 50000s, color 50000s, filter 50000s;
        }
        input {
          height: 100%;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        textarea {
          line-height: 1.13;
          min-height: 44px;
          padding-top: 0;
          padding-bottom: 14px;
        }

        input:disabled,
        textarea:disabled {
          color: ${colors.inputText};
          -webkit-text-fill-color: ${colors.inputText};
          opacity: 1;
        }

        .field.floating textarea {
          min-height: 30px;
        }
        .field.with-prefix input {
          padding-left: 0;
        }
        .prefix {
          transform: translateY(1px);
          flex-shrink: 0;
          display: flex;
          justify-content: flex-end;
          width: ${PADDING_LEFT_MOBILE + PREFIX_CONTENT_WIDTH}px;
          padding-bottom: ${PREFIX_PADDING_BOTTOM_MOBILE}px;
          padding-right: 2px;
          user-select: none;
          pointer-events: none;
          font-family: ${fonts.IBMPlex};
          font-size: 14px;
          line-height: 1;
          font-weight: normal;
          color: ${colors.grayText};
          opacity: 0;
          transition: opacity 0.3s ${transition.timingFunction};
        }
        .field.floating .prefix {
          opacity: 1;
        }
        @keyframes onAutoFillStart {
          from {
          }
        }
        @keyframes onAutoFillCancel {
          from {
          }
        }
        input:-webkit-autofill {
          animation-duration: 50000s;
          animation-name: onAutoFillStart;
        }
        input:-moz-autofill {
          animation-duration: 50000s;
          animation-name: onAutoFillStart;
        }
        input:not(:-webkit-autofill) {
          animation-duration: 50000s;
          animation-name: onAutoFillCancel;
        }
        input:not(:-moz-autofill) {
          animation-duration: 50000s;
          animation-name: onAutoFillCancel;
        }
        .label {
          position: absolute;
          top: 30px;
          left: ${PADDING_LEFT_MOBILE}px;
          transform: translateY(-50%) scale(1);
          font-family: ${fonts.IBMPlex};
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 1;
          pointer-events: none;
          transition: transform 0.3s ${transition.timingFunction};
          transform-origin: left top;
          color: ${colors.inputText};
        }
        .label.floating {
          transform: translateY(-120%) scale(0.82);
        }
        .multiline .label.floating {
          top: 30px;
        }
        .multiline.field.floating {
          padding-top: 30px;
        }
        .field.focused {
          border-color: ${colors.inputBorderFocus};
        }
        .field.has-error {
          border-color: ${colors.red};
        }
        input::placeholder,
        textarea::placeholder {
          opacity: 0;
          color: ${colors.inputText};
          transition: opacity 0.3s ${transition.timingFunction};
        }
        .focused input::placeholder,
        .focused textarea::placeholder {
          opacity: 1;
        }
        .error {
          margin-top: 3px;
          font-family: ${fonts.IBMPlex};
          font-size: 12px;
          line-height: 1;
          font-weight: normal;
          color: ${colors.red};
        }
        @media (min-width: ${media.desktop}px) {
          .field {
            background: transparent;
          }
          input {
            padding-left: ${PADDING_LEFT_DESKTOP}px;
            font-size: 16px;
          }
          .label {
            left: ${PADDING_LEFT_DESKTOP}px;
            font-size: 16px;
          }
          .prefix {
            transform: translateY(2px);
            width: ${PADDING_LEFT_DESKTOP + PREFIX_CONTENT_WIDTH}px;
            padding-bottom: ${PREFIX_PADDING_BOTTOM_DESKTOP}px;
          }
          .label.floating {
            transform: translateY(-120%) scale(0.72);
          }
          .error {
            font-size: 14px;
          }
        }
      `}</style>
    </div>
  )
}

// $FlowFixMe
export default React.forwardRef(TextInputField)