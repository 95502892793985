import * as React from 'react'
import cn from 'clsx'
import { type FieldProps } from 'formik'

import { media, colors } from '../../utils/style'

type Option = {
  label: string,
  value: any,
}

type Props = FieldProps & {
  options: Array<Option>,
  inverted?: boolean,
  selectedValue?: any,
  onChange?: Function,
}

export default function OptionGroup({
  options,
  field,
  form,
  inverted,
  onChange,
  selectedValue,
}: Props) {
  function handleOptionPress(nextValue) {
    if (form) {
      form.setFieldValue(field.name, nextValue)
    }

    if (typeof onChange === 'function') {
      onChange(nextValue)
    }
  }

  return (
    <ul className={cn({ inverted })}>
      {options.map((option, index) => (
        <li
          key={index.toString()}
          className={cn({
            selected:
              selectedValue === option.value || field?.value === option.value,
          })}
        >
          <button type="button" onClick={() => handleOptionPress(option.value)}>
            {option.label}
          </button>
        </li>
      ))}

      <style jsx>{`
        ul {
          display: flex;
          justify-content: space-between;
          margin: 0;
          padding: 0;

          list-style: none;
          border: 1px solid #e0e8ed;
          border-radius: 4px;

          overflow: hidden;

          background-color: ${colors.faqBg};
        }

        li {
          flex: 1;

          display: flex;
        }

        button {
          height: 30px;
          width: 100%;
          border: none;

          font: inherit;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;

          background: ${colors.faqBg};

          outline: none;
          cursor: pointer;

          color: #a3b1bb;
        }

        .selected button {
          background: ${colors.white};
          color: #14145a;
          cursor: default;
        }

        .inverted button {
          background: ${colors.white};
        }

        .inverted .selected button {
          background: ${colors.faqBg};
        }

        @media (min-width: ${media.desktop}px) {
          button {
            height: 40px;

            background: ${colors.white};
          }

          .selected button {
            background: ${colors.faqBg};
          }

          .inverted button {
            background: ${colors.faqBg};
          }

          .inverted .selected button {
            background: ${colors.white};
          }
        }
      `}</style>
    </ul>
  )
}
