// @flow
import * as React from 'react'
import cn from 'clsx'

import { buttonStyle } from '../../utils/style'

type Props = {
  children: React.Node,
  disabled: boolean,
  onClick: ?Function,
  className?: string,
  type: 'submit' | 'button',
  tabIndex?: number,
  outline: boolean
}

export default function Button({
  children,
  disabled,
  onClick,
  className,
  outline,
  tabIndex,
  type = 'button'
}: Props) {
  const extraProps = {}

  if (typeof onClick === 'function') {
    extraProps.onClick = onClick
  }

  if (typeof tabIndex === 'number') {
    extraProps.tabIndex = tabIndex
  }

  return (
    <button
      className={cn('button', className, { outline })}
      disabled={disabled}
      type={type}
      {...extraProps}
    >
      {children}

      <style jsx>{buttonStyle}</style>
    </button>
  )
}
