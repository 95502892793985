// @flow
import * as React from 'react'

import InputSuggestions from 'components/InputSuggestions'
import { TextInputFieldV2 as TextInputField } from './'

import { useCountry, DEFAULT_COUNTRY } from 'lib/withCountry'
import useDebouncedValue from 'lib/useDebouncedValue'
import { getSuggestions } from 'utils/places'

type Props = {
  field: {
    name: string,
    value: ?string,
  },
  form: { setFieldValue: Function },
}

export default function PlacesTextInputField(props: Props) {
  const { field, form } = props
  const { value } = field
  const { country } = useCountry()
  const countryCode = (country || DEFAULT_COUNTRY).toUpperCase()
  const inputRef = React.useRef()

  const [suggestions, setSuggestions] = React.useState(null)
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = React.useState<
    number | null
  >(null)

  const debouncedValue = useDebouncedValue(value)
  const debouncedValueRef = React.useRef(null)

  React.useEffect(() => {
    async function fetchSuggestions(address) {
      let nextSuggestions

      try {
        nextSuggestions = await getSuggestions(address, {
          componentRestrictions: { country: countryCode },
        })
      } catch (error) {
        console.log(error)
      }

      const currentInputRef = inputRef.current
      const isFocused =
        Boolean(currentInputRef) && currentInputRef === document.activeElement

      if (nextSuggestions && isFocused) {
        setSelectedSuggestionIndex(null)
        setSuggestions(
          nextSuggestions
            .map(({ place_id: value, address_components }) => ({
              value,
              label: address_components
                .filter(
                  (component) =>
                    component.types.includes('route') ||
                    component.types.includes('street_number')
                )
                .map((component) => component.long_name)
                .reverse()
                .join(', '),
            }))
            .filter(({ label }) => label)
        )
      }
    }

    if (debouncedValueRef.current !== debouncedValue) {
      if (debouncedValue) {
        fetchSuggestions(debouncedValue)
      } else {
        setSelectedSuggestionIndex(null)
        setSuggestions(null)
      }
    }

    debouncedValueRef.current = debouncedValue
  }, [debouncedValue, country])

  function handleInputBlur() {
    setSelectedSuggestionIndex(null)
    setSuggestions(null)
  }

  const handleInputKeyDown = React.useCallback(
    function (event) {
      const { keyCode } = event

      if (keyCode === 40) {
        // Key down
        if (Array.isArray(suggestions) && suggestions.length > 0) {
          if (suggestions.length - 1 !== selectedSuggestionIndex) {
            event.preventDefault()
            setSelectedSuggestionIndex(
              typeof selectedSuggestionIndex === 'number'
                ? selectedSuggestionIndex + 1
                : 0
            )
          }
        }
      } else if (keyCode === 38) {
        // Key up
        if (Array.isArray(suggestions) && suggestions.length > 0) {
          if (
            typeof selectedSuggestionIndex === 'number' &&
            selectedSuggestionIndex !== 0
          ) {
            event.preventDefault()
            setSelectedSuggestionIndex(selectedSuggestionIndex - 1)
          }
        }
      } else if (keyCode === 13) {
        // Enter key
        if (
          typeof selectedSuggestionIndex === 'number' &&
          Array.isArray(suggestions) &&
          suggestions.length > 0
        ) {
          const selectedSuggestion = suggestions[selectedSuggestionIndex]

          const currentInputRef = inputRef.current
          if (currentInputRef) {
            event.preventDefault()

            currentInputRef.blur()
            form.setFieldValue(field.name, selectedSuggestion.label)
          }
        }
      }
    },
    [suggestions, selectedSuggestionIndex, field.name, form]
  )

  function handleSuggestionSelect(suggestion) {
    const currentInputRef = inputRef.current
    if (currentInputRef) {
      currentInputRef.blur()
      form.setFieldValue(field.name, suggestion.label)
    }
  }

  React.useEffect(() => {
    const currentInputRef = inputRef.current
    if (currentInputRef) {
      currentInputRef.addEventListener('blur', handleInputBlur)
      currentInputRef.addEventListener('keydown', handleInputKeyDown)

      return function cleanup() {
        currentInputRef.removeEventListener('blur', handleInputBlur)
        currentInputRef.removeEventListener('keydown', handleInputKeyDown)
      }
    }
  }, [suggestions, selectedSuggestionIndex, handleInputKeyDown])

  return (
    <div className="root">
      {/* $FlowFixMe */}
      <TextInputField {...props} ref={inputRef} />

      {suggestions ? (
        <InputSuggestions
          suggestions={suggestions}
          selectedIndex={selectedSuggestionIndex}
          onSelect={handleSuggestionSelect}
        />
      ) : null}
      <style jsx>{`
        .root {
          position: relative;
        }
      `}</style>
    </div>
  )
}